import axios, { AxiosResponse } from "axios"
import YelpReview from "../models/yelp-review"
import { Technician } from "../models/technician"
import { InstagramFeed } from "../models/instagram-feed"

export const client = axios.create({
  baseURL: "https://ingeniumconcepts-api.herokuapp.com/ic-api",
})

export const submitContactForm = payload => client.post("/contact", payload)

export const fetchServices = () => client.get("/service")

export const fetchTechnicians = (): Promise<AxiosResponse<Technician[]>> =>
  client.get("/technician")
export const checkTechnicianAvailableSpots = (
  technicianId: string,
  totalEstServiceTime: number,
  today: string
) =>
  client.get(`/appointment/available-spots/${technicianId}`, {
    params: { totalEstServiceTime, today },
  })
export const createNewAppointment = payload =>
  client.post("/appointment", payload)

export const fetchDrinks = () => client.get("/drink")

export const fetchInstagramFeed = (): Promise<AxiosResponse<InstagramFeed[]>> =>
  client.get("/instagram")

export const fetchYelpReviews = (): Promise<AxiosResponse<YelpReview[]>> =>
  client.get("/yelp/reviews")

export const fetchPromotions = status =>
  client.get(`/promotion?status=${status}`)
export const checkPromoCode = code =>
  client.get(`/promotion/check?code=${code}`)
